const files = {
    "CV": "cv.pdf",
    "Parcours": "parcours_scolaire.pdf",
    "Accidents": "accidents_parcours.pdf",
    "Projection": "projection.pdf",
    "Formation": "formations_complementaires.pdf",
    "Attentes": "attentes_professionnelles.pdf",
    "Personnalité": "personnalite.pdf",
    "Hobby": "hobby.pdf",
};

export default files;
